import { useEffect } from "react";
import { getCHPYParamByName, setCookie } from "../utils/utils";

export default function useCommonHooks() {
  useEffect(() => {
    const campaignId = getCHPYParamByName("campaign_id");
    if (!campaignId) return;

    setCookie("campaign_id", campaignId);
  }, []);
}
